<template>
    <section class="mt-5">
        <TabMenu :links="subMenuLinks" />
    </section>
    <section class="mt-5">
        <router-view />
    </section>
</template>

<script>
    import LineChart from '@/components/chart/LineChart';
    import TabMenu from '@/components/general/TabMenu';

    export default {
        components: {
            LineChart,
            TabMenu,
        },
        // created() {
        //     this.$store.dispatch('energy_data/fetchElecEndexData').then(() => (this.isLoaded = true));
        // },
        data() {
            return {
                subMenuLinks: [
                    { to: { name: 'endexTtfData' }, display_name: this.$tc('endex_ttf', 1) },
                    { to: { name: 'spotTtfData' }, display_name: this.$tc('ttf_ztp_spot_market', 2) },
                    // { to: { name: 'ttfDamData' }, display_name: this.$tc('ttf_dam', 1) },
                ],
                isLoaded: false,
            };
        },
    };
</script>
